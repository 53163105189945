const permittedRoutes = {
  "uv coach": [
    "/hc",
    "/calls",
    "/redness",
    "/flareups",
    "/pso-flareups",
    "/callback-request",
    "/chat-room",
    "/calls",
    "/non-working-days",
  ],
  "psoriasis admin": ["/orders", "/reports"],
  doctor: ["/doctor-panel"],
  admin: [
    "/doctors",
    "/admin/users",
    "/admin/new",
    "/admin/patient",
    "/diet-template",
    "/hc/patients",
    "/schedule-management",
    "/leave-management",
    "/doctor-panel",
    "/notifications",
    "/admin/tags",
    // "/admin/add-patient",
  ],
  nutritionist: [
    "/hc",
    "/flareups",
    "/pso-flareups",
    "/chat-room",
    "/calls",
    "/diet-plan-queue",
    "/diet-template",
    "/non-working-days",
    "/callback-request",
    "/notifications",
    "/admin/tags",
    "/admin/broadcast-messages",
  ],
  psychologist: [
    "/hc",
    "/flareups",
    "/pso-flareups",
    "/chat-room",
    "/calls",
    "/non-working-days",
    "/callback-request",
    "/notifications",
    "/admin/tags",
    "/admin/broadcast-messages",
  ],
  "uv coach": ["/hc"],
  crm: [
    "/crm/patients",
    "/crm/assigned-patients",
    "/chat-room",
    "/calls",
    "/hc",
    "/non-working-days",
    "/notifications",
    // "/admin/tags",
  ],
  doctor: ["/doctor-panel"],
  kam: ["/doctor-panel"],
  patient: ["/nerivio"],
  "clinical design": ["/notifications", "/admin/patient", "/diet-template", "/hc/patients", "/pause-plan-requests"],
};

export default function hasPermission(page, auth) {
  if (["/", "/login"].some((url) => url === page)) return true;
  const routes = permittedRoutes[auth?.role];
  return routes.some((r) => page.startsWith(r));
}
